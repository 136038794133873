import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { axios } from './axios';
import { DataType } from '../scenes/PartnerContent/type';

const getVersionsByPage = async (page: string) => {
  try {
    const url = `/partnerDefinedContents?page=${page}`;
    const res: AxiosResponse = await axios.get(url);
    return res.data;
  } catch (error: any) {
    console.log(error.message);
    return [];
  }
};

const postNewContentVersion = async (page: string, partnerContent: any) => {
  try {
    const url = `/partnerDefinedContents`;
    const res: AxiosResponse = await axios.post(url, {
      page,
      partnerContent,
    });

    return res.data;
  } catch (error: any) {
    console.log(error.message);
    return error;
  }
};

const getSelectedContentVersion = async () => {
  try {
    const url = `/partnerDefinedContents/selected`;
    const res: AxiosResponse = await axios.get(url);

    return res.data;
  } catch (error: any) {
    console.log(error.message);
    return error;
  }
};

const getContentByVersionId = async (id: number) => {
  try {
    const url = `/partnerDefinedContents/${id}`;
    const res: AxiosResponse = await axios.get(url);

    return res.data;
  } catch (error: any) {
    console.error(error.message);
    return error;
  }
};

const useContentVersion = async (page: string | null, versionId: number | undefined) => {
  try {
    const url = `/partnerDefinedContents/use`;
    await axios.post(url, {
      page,
      versionId,
    });
  } catch (error: any) {
    console.error(error.message);
    return error;
  }
};

const useDefaultContentVersion = async (page: string | null) => {
  try {
    const url = `/partnerDefinedContents/use-default`;
    await axios.post(url, {
      page
    });
  } catch (error: any) {
    console.log(error.message);
    return error;
  }
}

const getContentByPartnerKey = async (page: string, partnerKey: string) => {
  try {
    const url = `/partnerDefinedContents/byPartnerKey`;
    const res: AxiosResponse = await axios.post(url, {
      page,
      partnerKey
    })

    return res.data
  } catch (error: any) {
    console.error(error.message);
  }
}

const getContentByWabaId = async (wabaId : string) => {
  try{
    const url = `/partnerDefinedContents/by-waba-id`;
    const res: AxiosResponse = await axios.post(url, {
      wabaId
    })

    return res.data
  } catch(err : any) {
    console.log(err.message)
  }
}


const uploadLogo = async (file : any) => {
  try {
    const formData = new FormData();
    // console.log(file)
    formData.append('logo', file);

    const url = '/partnerDefinedContents/logo-upload'
    const res : AxiosResponse = await axios.post(url, formData, {
      withCredentials: true,
      headers: { "Content-Type": "multipart/form-data" }
    })

    return res.data
  } catch (error : any) {
    console.log(error)
  }
}

const getCurrentLogo = async () => {
  try {
    const url = "/partnerDefinedContents/logo"
    const res : AxiosResponse = await axios.get(url)
    return res.data
  } catch (error : any) {
    console.log(error.message)
  }
}

const getLogoByPartnerKey = async (partnerKey : string) => {
  try {
    const url = "/partnerDefinedContents/logo-by-partner-key"
    const res : AxiosResponse = await axios.post(url, {
      partnerKey
    })
    return res.data
  } catch (error : any) {
    console.log(error.message)
  }
}


export {
  getVersionsByPage,
  postNewContentVersion,
  getSelectedContentVersion,
  getContentByVersionId,
  useContentVersion,
  getContentByPartnerKey,
  useDefaultContentVersion,
  getContentByWabaId,
  uploadLogo,
  getCurrentLogo,
  getLogoByPartnerKey
};
