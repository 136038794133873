import { AxiosResponse } from 'axios';
import { axios } from './axios';
import { Account, PhoneNumber } from '../types';

export type WabaPhoneNumber = {
  account: Account;
  newPhoneNumbers: PhoneNumberWithProvisionName[];
};

export type PhoneNumberWithProvisionName = {
  phoneNumber: PhoneNumber;
  provisionServerName: string | null;
}

type FindResponse = {
  accounts: Account[];
  total: number;
  selectedPage: number;
  selectedSize: number;
};

/**
 * Uses cookies to check if session still valid and user's self info
 * Useful for when user closes and opens the page
 */
const connect = async (
  oauthToken: string,
  partnerKey: undefined | string,
  customerId: undefined | string = undefined,
) => {
  try {
    const path = customerId ? '/wabas/connectWithCustomerId' : '/wabas/connect';
    const res: AxiosResponse<WabaPhoneNumber[]> = await axios.post(path, {
      oauthToken,
      partnerKey,
      ...(customerId && { customerId }),
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const find = async (isGettingAll = false, page = 1, size = 10, searchText = '') => {
  try {
    const res: AxiosResponse<FindResponse> = await axios.get(
      `/wabas?is_getting_all=${isGettingAll ? 1 : 0}&page=${page}&size=${size}${
        searchText && '&search=' + searchText
      }`,
    );
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const reload = async (wabaId: string) => {
  try {
    const res: AxiosResponse<FindResponse> = await axios.put(`/wabas/${wabaId}/reload`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getExportData = async () => {
  try {
    const res = await axios.get(`/wabas/export`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const remove = async (accountId: number) => {
  try {
    const res: AxiosResponse<string> = await axios.delete(`/wabas/${accountId}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const userCanceledFacebookFlow = async () => {
  try {
    await axios.post(`/wabas/userCanceledFacebookFlow`);
  } catch (error) {
    console.log(error);
  }
};

const userWithCustomerIdCanceledFacebookFlow = async (partnerToken: string, customerId: string) => {
  try {
    await axios.post(`/wabas/userWithCustomerIdCanceledFacebookFlow`, {
      partnerToken,
      customerId,
    });
  } catch (error) {
    console.log(error);
  }
};

const addWabaInfo = async (wabaId: string, ownerEmail: string, partnerEmail?: string) => {
  try {
    await axios.post(`/wabas/${wabaId}/addWabaInfo`, {
      ownerEmail,
      partnerEmail,
    });
    return true;
  } catch (error) {
    console.log(error.message);
    throw false;
  }
};

export {
  connect,
  find,
  reload,
  getExportData,
  remove,
  userCanceledFacebookFlow,
  userWithCustomerIdCanceledFacebookFlow,
  addWabaInfo,
};
