import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { PhoneNumber } from '../types';
import * as api from '../api';

type CloudApiWebhookUrlType = {
  value: PhoneNumber;
};

const EditCloudApiWebhookUrl = ({ value }: CloudApiWebhookUrlType) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { cloudApiWebhookUrl, phoneNumberId } = value;

  const openRegisterModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: { cloudApiWebhookUrlFormValue: string }) => {
    setIsLoading(true);
    try {
      const { cloudApiWebhookUrlFormValue } = values;
      const res = await api.phoneNumber.registerCloudApiWebhook(phoneNumberId, cloudApiWebhookUrlFormValue);
      if (res.success) {
        setIsLoading(false);
        notification.success({
          message: 'Success to register webhook URL',
          description: 'This URL has been successfully registered',
        });
      }
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
      notification.error({
        message: 'Fail to register webhook URL',
        description: error.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Fail to register webhook URL',
      description: errorInfo,
    });
  };

  return (
    <div>
      <Button icon={<EditOutlined />} onClick={openRegisterModal}></Button>
      <Modal
        title="Register cloud API webhook url for phone number"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ cloudApiWebhookUrlFormValue: cloudApiWebhookUrl }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="URL"
            name="cloudApiWebhookUrlFormValue"
            rules={[{ required: true, message: 'Please input the URL!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditCloudApiWebhookUrl;
