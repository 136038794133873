import React, { useEffect, useState } from 'react';
import { Me } from './types';
import './App.css';
import Routers from './router/Routes';
import { setUpdateLoginState, user } from './api';
import { initFacebookSdk } from './facebookSdk';

initFacebookSdk();

function App(): JSX.Element {
  const [me, setMe] = useState<null | Me>(null);
  setUpdateLoginState((newMe: null | Me) => {
    setMe(newMe);
    localStorage.setItem('email', newMe?.email || '');
  });
  useEffect(() => {
    if (!window.location.pathname.includes('callback')) {
      (async function () {
        await user.me();
      })();
    }
  }, []);
  return <Routers me={me} />;
}

export default App;
