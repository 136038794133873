import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Form } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import * as api from '../../api';
import { WabaPhoneNumber } from '../../api/account';
import { launchWhatsAppSignup } from '../../facebookSdk';
import { OnboardingStatus } from '../WhatsAppOnboarding';
import { UserStatusEnum } from '../../types';
import { Login } from '../Login';
import { useRemoveBg } from '../../hooks';

import { 
  getContentByPartnerKey,
  getLogoByPartnerKey 
} from '../../api/partnerDefinedContent';

import { DataType } from '../../scenes/PartnerContent/type';


type Props = {
  email: string | null;
  userOnboardingStatus: string | null;
};

function EmbeddedClient({ email, userOnboardingStatus }: Props) {
  const navigate = useNavigate();
  const [oauthToken, setOauthToken] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [agreeTnc, setAgreeTnc] = useState(false);
  const [withoutKRAccount, setWithoutKRAccount] = useState(false);
  const [connectResponse, setConnectResponse] = useState<WabaPhoneNumber[] | string | null>(null);
  const params = new URLSearchParams(location.search);
  const partnerKey = params.get('partner') || undefined;
  const customerId = params.get('customerId') || undefined;
  const isWithMigration = params.get('withMigration') === '1';

  const [logoUrl, setLogo] = useState("")

  // for storing using content data
  const [content, setContent] = useState<DataType | null>(null);
  const [shouldUseDefaultContent, setShouldUseDefaultContent] = useState(true)

  const fetchUsingContent = async (partnerKey: string | undefined) => {
    try {
      if (!partnerKey) throw new Error('No partnerKey');

      let page = ''
      // conditional fetching
      if (!customerId && !email) {
        page = 'WA_CLIENT_WITHOUT_CUSTOMERID'
      } else if (!customerId && email) {
        page = 'WA_CLIENT_WITH_CUSTOMERID'
      } else if (customerId && !isWithMigration) {
        page = 'WA_CLIENT_WITH_CUSTOMERID'
      } else if (customerId && isWithMigration) {
        page = 'WA_CLIENT_WITH_MIGRATION'
      }

      const data = await getContentByPartnerKey(page, partnerKey)
      if (data) {
        setContent(data.content);
        setShouldUseDefaultContent(false)
      } else {
        setShouldUseDefaultContent(true)
      }

    } catch (error: any) {
      console.error("using default content")
      setShouldUseDefaultContent(true)
    }
  };

  const fetchCurrentLogo = async (partnerKey : string) => {
    const logoUrl = await getLogoByPartnerKey(partnerKey)
    setLogo(logoUrl)
  }

  if (customerId && !withoutKRAccount) {
    setWithoutKRAccount(true);
  }

  const onClickOnboardingButton = async () => {
    try {
      setLoading(true);
      if (customerId) {
        await api.user.initiateOnboardingProcessWithCustomerId(customerId);
      } else if (userOnboardingStatus && userOnboardingStatus === UserStatusEnum.NotInitiated) {
        await api.user.initiateOnboardingProcess();
      }
      const oauthToken = await launchWhatsAppSignup();

      setOauthToken(oauthToken);
    } catch (err) {
      console.log(err);
      if (typeof err === 'string') {
        setConnectResponse(err);
      }
      if (withoutKRAccount) {
        await api.account.userWithCustomerIdCanceledFacebookFlow('' + partnerKey, '' + customerId);
      } else {
        await api.account.userCanceledFacebookFlow();
      }
    } finally {
      setLoading(false);
    }
  };
  const navigateToAccountPage = () => {
    navigate('accounts');
  };
  useEffect(() => {
    if (oauthToken) {
      const doWabaConnect = async () => {
        try {
          const data = await api.account.connect(oauthToken, partnerKey, customerId);
          setConnectResponse(data);
        } catch (err) {
          console.log(err);
        }
        setLoading(false);
      };
      doWabaConnect();
    } else {
      setLoading(false);
    }
  }, [oauthToken]);

  // fetch using content of this page
  useEffect(() => {
    fetchUsingContent(partnerKey);
    if(partnerKey){
      fetchCurrentLogo(partnerKey)
    }
  }, [email]);

  useRemoveBg()

  return (
    <div style={{ display : "flex", justifyContent: "center" }}>
      {/* logo */}
      {
        logoUrl &&
        <div style={{ padding : "16px" }}>
          <img src={logoUrl} alt="logo" style={{ width : "80px", height : "80px" }} />
        </div>
      }
      {/* content */}
      <div>
        {/* fix this - DONE */}
        {isWithMigration && (
          <>
            {shouldUseDefaultContent && <h2 style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>WABA Migration</h2>}
            {
              content?.title &&
              <h2 style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '2rem' }}>{
                ReactHtmlParser(content.title)
              }</h2>
            }
          </>
        )}
        <div
          style={{
            display: 'flex',
            maxWidth: '800px',
            margin: 'auto',
            flexDirection: 'row',
            justifyContent: isWithMigration ? 'space-between' : 'center',
            alignItems: 'flex-start',
            paddingTop: 56
          }}
        >
          {/* left content box - with migration  --DONE */}
          {isWithMigration && (
            <div style={{ maxWidth: '300px', border: '1px solid black', padding: '12px' }}>
              {
                content?.mainDescription &&
                ReactHtmlParser(content.mainDescription)
              }
              {
                shouldUseDefaultContent &&
                <>
                  Steps you will need to do: <br />
                  1. Login to your existing Facebook business manager account
                  <br />
                  2. Complete Step 1 fully, but don’t proceed to Step 2<br />
                  3. In Step 1, you will need to:
                </>
              }
              <br />
              {
                content?.secondaryDescription &&
                ReactHtmlParser(content.secondaryDescription)
              }
              {
                shouldUseDefaultContent &&
                <>
                  a.{' '}
                  <strong>
                    <u>Select</u>
                  </strong>{' '}
                  (and not create) your existing “Facebook Business Account” which is linked with the number you are
                  migrating over
                  <br />
                  b.{' '}
                  <strong>
                    <u>Create new</u>
                  </strong>{' '}
                  (and not select) “WhatsApp Business Account”. This is the new account your number will be migrating to.
                  <br />
                  c. Stop after you reach the account confirmation screen which will say “Accounts have been setup”. We
                  will{' '}
                  <strong>
                    <u>NOT</u>
                  </strong>{' '}
                  proceed to Step 2.
                </>
              }
            </div>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {!isWithMigration && (
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '2rem',
                  maxWidth: '720px',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {/* content if no customer id */}
                {!email && !withoutKRAccount && (
                  <>
                    {/* title */}
                    {content?.title && ReactHtmlParser(content.title)}
                    {shouldUseDefaultContent && <h3>Apply for WhatsApp Business APIs</h3>}

                    {/* mD */}
                    {content?.mainDescription && ReactHtmlParser(content.mainDescription)}
                    {
                      shouldUseDefaultContent &&
                      <p>To continue, please follow the steps below:</p>
                    }

                    {/* sD */}
                    <div style={{ maxWidth: '500px', textAlign: 'left' }}>
                      {content?.secondaryDescription &&
                        ReactHtmlParser(content.secondaryDescription)
                      }
                      {
                        shouldUseDefaultContent &&
                        <ol style={{ textAlign: 'left' }}>
                          <li>Create an account by clicking the “Register Now” button</li>
                          <li>
                            Once account created, you will be able to create/connect your Facebook Account and <br />{' '}
                            create a “WhatsApp Business API Account”
                          </li>
                        </ol>
                      }
                    </div>

                    {/* wM */}
                    <div style={{ backgroundColor: '#fcf5e9', padding: '1rem', fontWeight: 500 }}>
                      {content?.warningMessage && ReactHtmlParser(content.warningMessage)}
                      {
                        shouldUseDefaultContent &&
                        'Make sure you use the same email address you used for your Facebook Business Account.'
                      }
                    </div>
                  </>
                )}

                {/* here with customer id and without migration */}
                {!isWithMigration && (email || withoutKRAccount) && (
                  <>
                    {/* title */}
                    {content?.title && ReactHtmlParser(content.title)}
                    {shouldUseDefaultContent && <h3>Apply for WhatsApp Business APIs</h3>}

                    {/* mD */}
                    {content?.mainDescription && ReactHtmlParser(content.mainDescription)}
                    {
                      shouldUseDefaultContent &&
                      <p>
                        To continue, you will need to access your company’s Facebook business account.
                        <br />
                        By clicking the “Connect Facebook Business Account” button below you can:{' '}
                      </p>
                    }

                    {/* sD */}
                    {content?.secondaryDescription && ReactHtmlParser(content.secondaryDescription)}
                    {
                      shouldUseDefaultContent &&
                      <ol style={{ textAlign: 'left', listStylePosition: 'inside' }}>
                        <li>Create new or select existing Facebook and WhatsApp business accounts</li>
                        <li>Provide a display name and description for your WhatsApp business profile</li>
                        <li>Connect and verify your phone number to use for WhatsApp API access</li>
                      </ol>
                    }

                    {/* wM */}
                    <div style={{ backgroundColor: '#fcf5e9', padding: '1rem', fontWeight: 500 }}>
                      {content?.warningMessage && ReactHtmlParser(content.warningMessage)}
                      {shouldUseDefaultContent &&
                        <>
                          Do <span style={{ fontWeight: 900 }}>not</span> use a number that is currently connected to an
                          existing WhatsApp account.
                          <br />
                          This includes numbers that are connected to the WhatsApp Business App.
                        </>
                      }
                    </div>{' '}
                  </>
                )}
              </div>
            )}

            {/* action here */}
            <div style={{ textAlign: 'center' }}>
              {!withoutKRAccount && (
                <Login isLoggedIn={!!email} isEmbeddedPage={true}>
                  {!email ? (
                    <Button type="primary">
                      {
                        content?.actionList?.find((item) => item.type === 'button')?.text &&
                        ReactHtmlParser(content.actionList.find((item) => item.type === 'button')?.text)
                      }
                      {shouldUseDefaultContent && 'Register now'}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Login>
              )}
              {(email || withoutKRAccount) && (
                <div
                  style={{
                    marginTop: !isWithMigration ? '2rem' : '0',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {connectResponse && (
                    <>
                      <OnboardingStatus connectResponse={connectResponse} />
                      <Button
                        style={{ width: 'fit-content', marginTop: '1rem' }}
                        type="primary"
                        onClick={navigateToAccountPage}
                      >
                        Navigate to Portal
                      </Button>
                    </>
                  )}

                  {/* here too */}
                  {!connectResponse && (
                    <>
                      <Form.Item>
                        <Checkbox
                          disabled={loading}
                          checked={agreeTnc}
                          onChange={(e) => {
                            setAgreeTnc(e.target.checked);
                          }}
                        >
                          {
                            content?.actionList?.find((item) => item.type === 'checkbox')?.text &&
                            ReactHtmlParser(content.actionList.find((item) => item.type === 'checkbox')?.text)
                          }
                          {shouldUseDefaultContent && 'I agree to terms and conditions'}
                        </Checkbox>
                      </Form.Item>
                      <Button type="primary" disabled={!agreeTnc || loading} onClick={onClickOnboardingButton}>
                        {
                          content?.actionList?.find((item) => item.type === 'button')?.text &&
                          ReactHtmlParser(content.actionList.find((item) => item.type === 'button')?.text)
                        }
                        {
                          shouldUseDefaultContent && 'Connect Facebook Business Account/Add WhatsApp number'}
                        {loading && <LoadingOutlined />}
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmbeddedClient;
