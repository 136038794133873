import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom"

// import { useLocation } from "react-router"
import { ShareProps } from "./types"

function Private({ isLoggedIn } : ShareProps) : JSX.Element {
  const location = useLocation()

  if(isLoggedIn){
    return <Outlet />
  }
  return <Navigate to="/" state={{ from: location }} replace={true} />
}

export default Private;