import React, { useState } from 'react';
import { User } from '../../types';
import { Button } from "antd"
import * as api from "../../api"

type UserActivationType = {
  record : User
}
function UserActivation({ record } : UserActivationType) {

  const [partner, setPartner] = useState(record?.partner);
  const [isLoading, setIsLoading] = useState(false);

  const onActivationChanged = async (partnerId: number | undefined, isActivated: boolean) => {
    try {
      setIsLoading(true);

      if (partnerId) {
        const updatedPartner = await api.partner.changeActivation(partnerId, isActivated);

        setPartner(updatedPartner);
      }
    } catch (error : any) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>{
      !record.isAdmin && record.partner?.id && 
      <>
        {partner?.isActivated ? (
          <Button
            type="primary"
            shape="round"
            size="small"
            loading={isLoading || false}
            onClick={() => onActivationChanged(partner?.id, false)}
          >
            Deactivate
          </Button>
        ) : (
          <Button
            type="primary"
            shape="round"
            size="small"
            loading={isLoading || false}
            onClick={() => onActivationChanged(partner?.id, true)}
          >
            Activate
          </Button>
        )}
      </>
    }</>
  );
}

export default UserActivation;