import { Button, Form, Input, Modal, notification } from 'antd';
import React, { useState } from 'react';
import * as api from '../api';

const { TextArea } = Input;
const notificationCreatedSuccess = () => {
  notification['success']({
    message: `Migration account to Cloud API Successfully`,
  });
};

const notificationCreatedFail = () => {
  notification['error']({
    message: `Migration account to Cloud API Failed!!`,
  });
};

const RegisterCloudApiMigrationButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      console.log(values);
      const isUpdated = await api.cloudMigration.register(values.phoneNumber, {
        pin: values.pin,
        backup: {
          password: values.backupPass,
          data: values.backupData,
        },
      });
      if (isUpdated) {
        handleCancel();
        notificationCreatedSuccess();
      }
    } catch (error: any) {
      handleCancel();
      notificationCreatedFail();
      console.error(error);
    } finally {
      form.resetFields();
      setIsLoading(false);
    }
  };
  return (
    <>
      <Button type="primary" onClick={showModal} style={{ marginLeft: '2px' }}>
        Cloud Api Migration
      </Button>
      <Modal
        title="Register Cloud Api Migration"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item label="Phone number ID" name="phoneNumber">
            <Input />
          </Form.Item>

          <Form.Item label="Pin" name="pin">
            <Input />
          </Form.Item>

          <Form.Item label="Backup data" name="backupData">
            <TextArea />
          </Form.Item>
          <Form.Item label="Backup data password" name="backupPass">
            <Input type="password" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RegisterCloudApiMigrationButton;
