import Table, { ColumnsType } from 'antd/lib/table';
import { format } from 'date-fns';
import React from 'react';
import { CloudApiMigration, PhoneNumber } from '../../types';

type CloudApiMigrationTableProps = {
  migrations: CloudApiMigration[];
  loading: boolean;
  isAdmin: boolean | null;
  page: number;
  size: number;
  total: number;
  onPaginationChanged(page: number, pageSize?: number | undefined): void;
};

const CloudApiMigrationTable = ({
  migrations,
  loading,
  isAdmin,
  page,
  size,
  total,
  onPaginationChanged
}: CloudApiMigrationTableProps) => {
  const columns: ColumnsType<CloudApiMigration> = [
    {
      title: 'Last migration date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: function CreatedAt(createdAt) {
        return <>{format(new Date(createdAt), 'd MMM yyyy')}</>;
      },
    },
    {
      title: 'Owner Email',
      dataIndex: 'phoneNumber',
      key: 'ownerEmail',
      align: 'center',
      render: function OwnerEmail(phoneNumber: PhoneNumber) {
        return <>{phoneNumber?.account.user?.email}</>
      }
    },
    ...(isAdmin
      ? [
          {
            title: 'Partner',
            dataIndex: 'phoneNumber',
            key: 'partnerEmail',
            render: function PartnerEmail(phoneNumber: PhoneNumber) {
              return <>{phoneNumber.account.user?.partner?.user?.email}</>
            }
          },
          {
            title: 'WABA Id',
            dataIndex: 'phoneNumber',
            key: 'WABAId',
            render: function PartnerEmail(phoneNumber: PhoneNumber) {
              return <>{phoneNumber.account.wabaId}</>
            }
          },
          {
            title: 'WABA Name',
            dataIndex: 'phoneNumber',
            key: 'WABAName',
            render: function PartnerEmail(phoneNumber: PhoneNumber) {
              return <>{phoneNumber.account.name}</>
            }
          },
        ]
      : []),
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: function PhoneNumber(phoneNumber: PhoneNumber) {
        return (
          <div className="flex flex-dir-c flex-jc-sb flex-ai-c">
            <span>{phoneNumber.value.trim()}</span>
          </div>
        )
      },
    },
    {
      title: 'Migration status',
      dataIndex: 'isSuccess',
      key: 'isSuccess',
      render: function migrationStatus(isSuccess: boolean) {
        return <>{isSuccess ? "Success" : "Failed"}</>
      }
    },
    {
      title: 'Migration Details',
      dataIndex: 'details',
      key: 'details',
    },
    {
      title: 'Requester',
      dataIndex: 'requester',
      key: 'requester',
      align: 'center',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={migrations.map((x) => {
        return {
          ...x,
          key: x.id,
        };
      })}
      bordered
      loading={loading}
      scroll={{ x: true }}
      pagination={{
        defaultCurrent: 1,
        current: page,
        total: total,
        showSizeChanger: true,
        defaultPageSize: 10,
        pageSize: size,
        position: ['bottomRight'],
        size: 'small',
        onChange: (page: number, pageSize?: number | undefined) => onPaginationChanged(page, pageSize),
      }}
    />
  );
};

export default CloudApiMigrationTable;
