import { Button, Form, Input, InputNumber, Modal, notification } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import * as api from '../api';

const RegisterCloudAPIButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openRegisterModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values: { phoneNumberId: string; pin: string }) => {
    try {
      setIsLoading(true);
      const response = await api.cloudMigration.registerNewPhoneNumber(
        values.phoneNumberId,
        values.pin,
      );
      if (response.status !== 200) {
        setIsLoading(false);
        notification.error({
          message: 'Fail to register this number to WhatsApp Cloud API ',
          description: response.message,
        });
      } else {
        setIsLoading(false);
        notification.success({
          message: 'Success to register to WhatsApp Cloud API',
          description: 'This phone number has been successfully registered to WhatsApp Cloud API',
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      notification.error({
        message: 'Fail to register this number to WhatsApp Cloud API ',
        description: error.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="cloud_api_button">
      <Button
        type="primary"
        title="Register to Cloud API"
        onClick={openRegisterModal}
      >Register To Cloud API</Button>

      <Modal
        title="Register phone number to WhatsApp Cloud API"
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ pin: '', wabaToken: '', phoneNumberId: '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Phone Number ID"
            name="phoneNumberId"
            rules={[{ required: true, message: 'Please input your phone number ID!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="New 6 digit PIN"
            name="pin"
            rules={[{ required: true, message: 'Please input your new pin for phone number!' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              maxLength={6}
              placeholder="Input your new 6 digits pin for the phone number"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RegisterCloudAPIButton;
