import { AxiosResponse } from 'axios';
import { axios } from './axios';
import { Usage } from '../types';

const getUsage = async (queryPath = '') => {
  try {
    const res: AxiosResponse<Usage> = await axios.get(`/usage?${queryPath}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export { getUsage };
