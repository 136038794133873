import React, { useEffect, useState } from 'react';

import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { Button, Card, DatePicker, Tooltip, Select } from 'antd';
import { StockOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as api from '../../api';
import { Usage, Account, ConversationData } from '../../types';
import UsageTable from './UsageTable';
import UsageChart from './UsageChart';
import { ExportCSV } from '../../components';

const { RangePicker } = DatePicker;
const { Option } = Select;

type AnalyticsChart = (ConversationData & { date : string})[];
type Collector = {
  "Date": string;
  "Phone Number": string;
  "Conversation Type": string;
  "Initiated By": string;
  "Number of Conversation": number;
  "Charges": string;
}


function UsagePage() {
  const [record, setRecord] = useState<Usage | null>(null);
  const [analyticsChart, setAnalyticsChart] = useState<AnalyticsChart>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [dates, setDates] = useState<RangeValue<Moment>>([moment(), moment()]);
  const [isInputDisabled, setInputDisable] = useState<boolean>(false);
  const [filtedWabaId, setFiltedWabaId] = useState<string>();
  const [filtedPhoneNumber, setFiltedPhoneNumber] = useState<string>('all');
  const [isChart, setIsChart] = useState(false);
  const [isDisbledChart, setIsDisbledChart] = useState(false);

  useEffect(() => {
    const fetchAccounts = async () => {
      const { accounts } = await api.account.find(true);
      setAccounts(accounts);
    };
    fetchAccounts();
  }, []);

  // useEffect(() => {
  //   console.log(record)
  // }, [record])

  const fetchUsage = async (queryPath: string) => {
    try {
      setInputDisable(true);
      const usage = await api.usage.getUsage(queryPath);
      setAnalyticsChart(
        usage.dividedDataEachDates.map((x) => {
          return {
            ...x,
            date: moment(new Date(x.start*1000)).format('YYYY/MM/DD'),
          };
        }),
      );
      setRecord(usage);
    } catch (error : any) {
      console.error(error.message);
      setRecord(null);
    } finally {
      setInputDisable(false);
    }
  };

  const isDisabledDate = (currentDate: Moment): boolean => {
    if (dates) {
      return currentDate.isAfter(moment()) || currentDate.isBefore(moment().subtract(31 * 3, 'days'));
    }
    return false;
  };

  const handleSearch = async () => {
    const startDate = dates && dates[0] ? dates[0] : moment();
    const endDate = dates && dates[1] ? dates[1] : moment();

    if (endDate.diff(startDate, 'days') >= 1) {
      setIsDisbledChart(false);
      setIsChart(true);
    } else {
      setIsDisbledChart(true);
      setIsChart(false);
    }

    let queryPath = `&start=${startDate.valueOf()}`;
    queryPath += `&end=${endDate.valueOf()}`;
    queryPath += `&wabaId=${filtedWabaId}`;
    queryPath += filtedPhoneNumber != 'all' ? `&phoneNumbers=${filtedPhoneNumber}` : '';
    fetchUsage(queryPath);
    // setQueryPath(queryPath)
  };

  const handleCalendarChange = (val: RangeValue<moment.Moment>) => {
    // if (anaType === 'MONTH') {
    //   setDates;
    // }
    setDates(val);
  };

  const dateFormat = 'YYYY/MM/DD';
  const optionsWabaId = accounts.map((waba) => (
    <Option value={waba.wabaId} key={waba.wabaId}>
      {`${waba.wabaId}-${waba.name}`}
    </Option>
  ));
  const optionsPhoneNumbers = accounts
    .find((waba) => waba.wabaId == filtedWabaId)
    ?.phoneNumbers?.map((phoneNumber) => (
      <Option value={phoneNumber.value} key={phoneNumber.id}>
        {phoneNumber.value}
      </Option>
    ));
  
  /**
   * record structure:
   * 
   * date
   * phone number
   * conversation type
   * initiated by
   * number of conversation
   * charges
   */

  const createRecord = (
    date: string, 
    phone : string,
    conversationType : string,
    initBy : string,
    noConversation : number,
    charges : number
  ) : Collector => ({
    "Date" : date,
    "Phone Number" : phone,
    "Conversation Type" : conversationType,
    "Initiated By" : initBy,
    "Number of Conversation" : noConversation,
    "Charges" : `$${charges}`
  })

  const createRecords = (data : ConversationData & { date : string }, phoneNumbers :string) : Collector[] => {
    const collector : Collector[] = []
    if(data.FREE_ENTRY){
      collector.push(createRecord(
        data.date,
        phoneNumbers,
        "FREE_ENTRY_POINT",
        "",
        data.FREE_ENTRY,
        data.cost
      ))
    }
    if(data.FREE_TIER){
      collector.push(createRecord(
        data.date,
        phoneNumbers,
        "FREE_TIER",
        "",
        data.FREE_TIER,
        data.cost
      ))
    }
    if(data.PAID_BUSINESS){
      collector.push(createRecord(
        data.date,
        phoneNumbers,
        "REGULAR (paid)",
        "BUSINESS_INITIATED",
        data.PAID_BUSINESS,
        data.cost
      ))
    }
    if(data.PAID_USER){
      collector.push(createRecord(
        data.date,
        phoneNumbers,
        "REGULAR (paid)",
        "USER_INITIATED",
        data.PAID_USER,
        data.cost
      ))
    }
    return collector
  }

  const getExportData = async () => {
    try {
      if(analyticsChart && record) {
        const phoneNumbers = _.map(record.phoneNumbers, (phoneNumber) => phoneNumber.value).join(' \r\n')
        const collectors = analyticsChart
        .map(data => createRecords(data, phoneNumbers))

        return [].concat(...collectors as any[])
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <Card className="text-center usage" title="Usage">
      <div className="flex flex-jc-sb flex-ai-c" style={{ marginBottom: '1rem' }}>
        <RangePicker
          allowEmpty={[false, false]}
          defaultValue={[moment(), moment()]}
          picker={'date'}
          format={dateFormat}
          value={dates}
          onCalendarChange={handleCalendarChange}
          disabledDate={isDisabledDate}
        />
        <Select
          showSearch
          disabled={isInputDisabled}
          defaultValue="Choose Waba"
          autoFocus
          style={{ width: 300, marginLeft: '20px' }}
          placeholder="Filter with WabaId"
          dropdownStyle={{ maxWidth: '400px' }}
          onSelect={(value) => {
            setFiltedWabaId(value);
            setFiltedPhoneNumber('all');
          }}
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {optionsWabaId}
        </Select>
        {filtedWabaId != 'all' && (
          <Select
            showSearch
            disabled={isInputDisabled || filtedWabaId == 'all'}
            value={filtedWabaId == 'all' ? 'Filter All Phone Numbers' : filtedPhoneNumber}
            defaultValue="Filter All Phone Numbers"
            style={{ width: 180, marginLeft: '20px' }}
            placeholder="Filter with Phone Numbers"
            onSelect={(value) => setFiltedPhoneNumber(value)}
          >
            <Option value="all">Filter all Phone Numbers</Option>
            {optionsPhoneNumbers}
          </Select>
        )}
        <Tooltip placement="top" title={isDisbledChart ? `Pick date range to display` : ''}>
          <Button
            style={{ marginLeft: '20px' }}
            type="primary"
            disabled={isInputDisabled || !filtedWabaId || isDisbledChart}
            onClick={() => setIsChart(!isChart)}
          >
            {isChart ? 'Display Table' : 'Display Chart'}
          </Button>
        </Tooltip>
        <Tooltip placement="top" title={filtedWabaId ? '' : 'Choose waba to analysis'}>
          <Button
            style={{ marginLeft: '20px' }}
            type="primary"
            icon={<StockOutlined />}
            disabled={isInputDisabled || !filtedWabaId}
            onClick={handleSearch}
          >
            Analyse
          </Button>
        </Tooltip>
        <ExportCSV
          disable = { record === null }
          style={{ marginLeft: '5px' }}
          getExportData={getExportData}
          fileName="analytics"
          // multiSheets={{ fieldSheetName: 'WABA Id' }}
        />
      </div>
      {isChart
        ? record && (
            <UsageChart
              loading={isInputDisabled}
              usageList={analyticsChart}
              totalCost={record.totalCost}
              totalConversation={
                record.FREE_ENTRY + 
                record.FREE_TIER + 
                record.PAID_BUSINESS +
                record.PAID_USER
              }
            />
          )
        : record && <UsageTable loading={isInputDisabled} usageList={[record]} />}
    </Card>
  );
}

export default UsagePage;
