import React, { useState } from 'react';
import { Button, notification, Modal, Form, Input } from 'antd';
import * as api from '../api';

const notificationCreatedSuccess = () => {
  notification['success']({
    message: `Created WABA Successfully`,
  });
};

const notificationCreatedFail = () => {
  notification['error']({
    message: `Created WABA Failed. Because Waba Info Is Exist`,
  });
};

const AddWabaInfoButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

   
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const isUpdated = await api.account.addWabaInfo(values.wabaId, values.ownerEmail, values.partnerEmail);
      if (isUpdated) {
        handleCancel();
        notificationCreatedSuccess();
      }
    } catch (error) {
      handleCancel();
      notificationCreatedFail();
      console.error(error.message)
    } finally {
      form.resetFields();
      setIsLoading(false);
    }
  };
  return (
    <>
      <Button type="primary" onClick={showModal} style={{ marginLeft: '2px' }}>
        ADD WABA
      </Button>
      <Modal title="Add Waba Info" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          initialValues={{
            remember: true,
          }}
        >
          <Form.Item label="Waba ID" name="wabaId">
            <Input />
          </Form.Item>

          <Form.Item label="Owner Email" name="ownerEmail">
            <Input />
          </Form.Item>

          <Form.Item label="Partner Email" name="partnerEmail">
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddWabaInfoButton;
