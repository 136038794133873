import React, { useState } from 'react';
import { User } from '../../types';
import { Button, notification } from "antd"
import * as api from "../../api"

type UserIsActiveType = {
  record : User, 
  updateUser : () => void
}
function UserIsActive({ record, updateUser } : UserIsActiveType) {

  const [isLoading, setIsLoading] = useState(false);
  const onDeactiveUser = async (userId: number | undefined, isActivated: boolean) => {
    try {
      setIsLoading(true);
      if (userId) {
        const updatedUser : User = await api.user.deactiveUser(userId, isActivated);
        if(updatedUser){
          notification.success({
            message : 'Deactivate user success!',
            description : `User ${updatedUser.email} has been deactivated`
          })
          updateUser();
        }
      }
    } catch (error : any) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      shape="round"
      size="small"
      loading={isLoading || false}
      onClick={() => onDeactiveUser(record.id, false)}
      disabled={ !record.isActive }
    >
      Deactivate User
    </Button>
  );
}

export default UserIsActive;