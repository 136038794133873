import { Button } from 'antd';
import React from 'react';
import { Login } from '../Login';



function Home({ email }: { email: string | null }) {
  return (
    <div id="home" className="flex flex-jc-c flex-ai-c">
      <div id="login-section-container" className='dark-glass-container'>
        <Login isLoggedIn={!!email} isEmbeddedPage={false}>
          <Button id='login-button'>Login / Sign up</Button>
        </Login>
      </div>
    </div>
  );
}

export default Home;
