import React from 'react';
import { Badge, Card, Button, Checkbox, notification } from 'antd';
import { PartnerContentPreviewerProps } from './type';
import ReactHtmlParser from 'react-html-parser';
import { 
  useContentVersion,
  useDefaultContentVersion
} from '../../api/partnerDefinedContent';

import { keysGeneration } from "./utils"

function CardTitle({ version }: any) {
  return version.title === undefined ? (
    <p className="text-left">Let choose a version</p>
  ) : (
    <p className="text-left">
      Version: <span style={{ textDecoration: 'underline' }}>{(new Date(version.title)).toLocaleString()}</span>
      <Badge
        style={{ margin: '0 0 0 2em' }}
        status={version.isUsing ? 'processing' : 'default'}
        text={version.isUsing ? <span className="active">Using</span> : <span className="in-active">Not using</span>}
      />
    </p>
  );
}

function PartnerContentPreviewer({
  content,
  selectedPage,
  selectedContent,
  selectedVersionId,
  refreshing
}: PartnerContentPreviewerProps) {
  const keys = selectedPage && keysGeneration(selectedPage)

  const onUseContent = async () => {
    try {
      await useContentVersion(selectedPage, selectedVersionId);
      refreshing()
      notification.success({
        message: `Success!`,
        description: `This content is using now!`,
        placement: 'topRight',
      });
    } catch (error) {
      notification.error({
        message: `Error!`,
        description: `Ops! Something wrong...`,
        placement: 'topRight',
      });
    }
  };

  // const onUseDefaultContent = async () => {
  //   try {
  //     await useDefaultContentVersion(selectedPage);
  //     refreshing()
  //     notification.success({
  //       message: `Success!`,
  //       description: `You are using Default Content now!`,
  //       placement: 'topRight',
  //     });
  //   } catch (error) {
  //     notification.error({
  //       message: `Error!`,
  //       description: `Ops! Something wrong...`,
  //       placement: 'topRight',
  //     });
  //   }
  // }

  // get status
  const isUsing = 
    !selectedContent || !selectedPage ? 
    false : 
    (selectedContent[selectedPage] === content?.id)


  return (
    <Card
      style={{ height: 'min-content' }}
      title={
        <CardTitle
          version={{
            title: content?.version,
            isUsing
            
          }}
        />
      }
    >

      {/* render normal fields */}
      {content &&
        keys && keys.map((item) => (
          <React.Fragment key={item.key}>
            {
              !(
                selectedPage === "WA_CLIENT_WITH_MIGRATION" && 
                item.key === "warningMessage"
              ) &&
              <div className="card-item">
                <div className="card-item__title">{item.title}:</div>
                <div className="card-item__body">{ReactHtmlParser(content.content[item.key])}</div>
              </div>
            }
          </React.Fragment>
        ))
      }

      {/* render action fields */}
      {content && content.content.actionList && (
        <div className="card-item">
          <div className="card-item__title">Actions:</div>
          <div className="card-item__body">
            {content.content.actionList?.map((action, index) => (
              <div key={index} style={{ marginBottom: '1em' }}>
                {action.type === 'checkbox' ? (
                  <Checkbox>{ReactHtmlParser(action.text)}</Checkbox>
                ) : (
                  <Button type="primary">{ReactHtmlParser(action.text)}</Button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {content && (
        <div>
          <Button disabled={isUsing} type="primary" style={{ marginTop: '20px' }} onClick={onUseContent}>
            Use This Content
          </Button>
          {/* {
            isUsing &&
            <Button 
              type="primary"
              style={{ margin: '0 20px 0 20px' }}
              onClick={onUseDefaultContent}
            >Use Default Content</Button>
          } */}
        </div>
      )}
    </Card>
  );
}

export default PartnerContentPreviewer;
