import { auth, user } from '../../api';
import { enrichCohereUserData } from '../../cohere';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Callback() {
  const [isProcessing, setIsProcessing] = useState(false);
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const code = params.get('code');
  const state = params.get('state');


  async function handleLogin(code: string, state: string) {
    setIsProcessing(true);
    const res = await auth.login(code, state);
    if (!res) {
      auth.logout();
      notification.error({
        message: 'Login failed, please try again later!',
      });
      navigate('/', {replace: true});
      return;
    }

    const userEmail = res.email;
    if (userEmail) {
      enrichCohereUserData({
        displayName: userEmail,
        email: userEmail,
      });
    }
    if (state?.includes('_embeded')) {
      navigate('/wa-client', {replace: true});
      return;
    }

    const { partnerId, isAdmin, isActive } = await user.me();

    if (!isActive) {
      auth.logout();
      notification.warning({
        message: 'You can not loggin',
        description: 'Your account has been deactivated!',
      });
      navigate('/', {replace: true});
      return;
    }

    navigate('/accounts', {replace: true});
  }

  
  useEffect(() => {
    if (isProcessing) return;

    if (!code || !state) {
      auth.logout();
      notification.error({
        message: 'Login failed, please try again later!',
      });
      navigate('/', {replace: true});
      return;
    }
    handleLogin(code, state);
  });

  return (
    <div id="callback" className="flex flex-jc-c flex-ai-c">
      <div className="dark-glass-container"></div>
    </div>
  );
}

export default Callback;
