import { AxiosResponse } from 'axios';
import { axios } from './axios';
import { PhoneNumber } from '../types';

type PhoneCert = {
  id: number;
  certificate: string;
  value: string;
};

type FindResponse = {
  phoneNumbers: PhoneNumber[];
  total: number;
  selectedPage: number;
  selectedSize: number;
};

/**
 * Uses cookies to check if session still valid and user's self info
 * Useful for when user closes and opens the page
 */
const find = async (isGettingAll = false, page = 1, size = 10, searchText = '') => {
  try {
    let query = `isGettingAll=${isGettingAll}`;
    query += `&page=${page}`;
    query += `&size=${size}`;
    query += `&search=${encodeURIComponent(searchText)}`;

    const res: AxiosResponse<FindResponse> = await axios.get(`/phones?${query}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getPhoneCert = async (phoneNumberId: string) => {
  try {
    const phoneCert: AxiosResponse<PhoneCert> = await axios.get(`/phones/${phoneNumberId}/cert`);
    return phoneCert.data;
  } catch (error) {
    console.log(error);
  }
};
const remove = async (phoneNumberId: number) => {
  try {
    const res: AxiosResponse<string> = await axios.delete(`/phones/${phoneNumberId}`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const requestCode = async (phoneNumberId: number) => {
  try {
    const res: AxiosResponse<boolean> = await axios.post(`/phones/${phoneNumberId}/requestCode`);
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const verifyCode = async (phoneNumberId: number, code: string) => {
  try {
    const res: AxiosResponse<boolean> = await axios.post(`/phones/${phoneNumberId}/verifyCode`, { code });
    return res.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getExportData = async () => {
  try {
    const res = await axios.get(`/phones/export`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const registerCloudApiWebhook = async(phoneNumberId: string, url: string) => {
  try {
    const res = await axios.post(`/phones/register-webhook-url/${phoneNumberId}`, {url});
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export { find, remove, getPhoneCert, requestCode, verifyCode, getExportData, registerCloudApiWebhook };
