import React, { useEffect, useState } from 'react';
import { PartnerContentHeaderProps, VersionType } from './type';
import { Select, Badge, Button, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function PartnerContentHeader({
  listOfVersion,
  handleSelectPage,
  handleSelectVersion,
  shouldDisable,
  toCreatingMode,
  usingContent,
  selectedPage,
  refreshing
}: PartnerContentHeaderProps) {

  const handleChange = (selectedId: any) => {
    if(selectedId === "USE_DEFAULT_VERSION"){
      const version = {version : "USE_DEFAULT_VERSION"} as any
      handleSelectVersion(version);
    } else {
      const version = listOfVersion.find((item) => item.id === selectedId) || null;
      handleSelectVersion(version);
    }
  };
  const { Option } = Select;

  //
  const usingContentId = usingContent && selectedPage && usingContent[selectedPage]

  //

  return (
    <div style={{
      display : "flex",
      justifyContent : "space-between",
      flexWrap : "wrap"
    }} >
      <div style={{
        display : "flex",
        justifyContent : "space-between",
        flexWrap : "wrap"
      }}>
        {/* page selector */}
        <Select style={{ marginRight: '0.5em', minWidth: 320 }} placeholder="Select a page" onChange={handleSelectPage}>
          {/* <Option value="CONNECT">Connect</Option> */}
          <Option value="WA_CLIENT_WITH_CUSTOMERID">Embed connect page with customer email</Option>
          <Option value="WA_CLIENT_WITHOUT_CUSTOMERID">Embed connect page without customer email</Option>
          <Option value="WA_CLIENT_WITH_MIGRATION">Embed connect page with migration</Option>
          <Option value="PHONE_MIGRATION">Phone migration page</Option>
          <Option value="PARTNER_LOGO">Partner Logo</Option>
        </Select>

        {/* Version selector */}
        {listOfVersion.length > 0 && (
          <Select
            placeholder="Select a version"
            style={{ minWidth: 280 }}
            onChange={handleChange}
            disabled={shouldDisable}
          >
            <Option key="USE_DEFAULT_VERSION" value="USE_DEFAULT_VERSION"> Or use <b>Default version</b> </Option>
            {listOfVersion.map((item) => (
              <Option key={item.id} value={item.id}>
                {new Date(item.version).toLocaleString()}
                {
                  <Badge
                    style={{ margin: '0 0 0 2em' }}
                    status = {usingContentId && item.id === usingContentId ? "processing" : "default"}
                  />
                }
              </Option>
            ))}
          </Select>
        )}
      </div>
      {/* Title */}
      {!shouldDisable ? <p>Preview your content</p> : <p>Create new version of your content</p>}

      {/* Create new version button */}
      <Button disabled={shouldDisable} onClick={toCreatingMode} shape="circle" icon={<PlusOutlined />} />
    </div>
  );
}

export default PartnerContentHeader;
