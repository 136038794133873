import React from 'react';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Form, Button } from "antd"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

type DynamicFormItemProps = {
    name : string
    title : string
    onFieldListChange : (key: string, index: number, value: string) => void
    initialValues : string[] | undefined
}

function DynamicFormItem({ 
    name, 
    title, 
    onFieldListChange,
    initialValues
} : DynamicFormItemProps) {

    return (
        <Form.List name={name}>
            {
                (fields, { add, remove }) => (
                <div className='dynamic-form-item'>
                    <div className='dynamic-form-item__label'>{ title }:</div>
                    <div className='dynamic-form-item__list'>
                        {
                            fields.map((field, index) => (
                                <Form.Item 
                                    key={field.key} 
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[{ required: true, message: 'This is required field' }]}
                                >
                                    <div className='dynamic-form-item__list__item' style={{ width : '100%' }}>
                                        {/* input */}
                                        <div style={{ flexGrow: 1 }}>
                                            <CKEditor 
                                                config={{         
                                                    toolbar: ['bold', 'italic', 'link',
                                                    '|', 'undo', 'redo']
                                                }}
                                                editor={ClassicEditor}
                                                onChange={(event:any, editor: any) => {
                                                    onFieldListChange(name, field.name, editor.getData())
                                                }}
                                                data = { initialValues && initialValues?.length > 0 && initialValues[field.key] }
                                            />
                                        </div>
                                
                                        {/* delete button */}
                                        <MinusCircleOutlined
                                            style={{ marginLeft : "0.5em", fontSize: "24px" }}
                                            onClick={() => remove(field.name)}
                                        /> 
                                    </div>
                                </Form.Item>
                            ))
                        }
                        <Form.Item>
                            <Button icon={<PlusOutlined/>} onClick={add} >
                                Add a {name === "checkboxs" ? 'checkbox' : 'button'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>)
            }
        </Form.List>
    );
}

export default DynamicFormItem;