
export const defaultContentData = {
    WA_CLIENT_WITH_CUSTOMERID : {
        title : `<h3>Apply for WhatsApp Business APIs</h3>`,
        mainDescription : `<p>
            To continue, you will need to access your company’s Facebook business account.
            <br />
            By clicking the “Connect Facebook Business Account” button below you can:
        </p>`,
      secondaryDescription : `<ol style=" text-align: 'left', list-style-position: 'inside' ">
            <li>Create new or select existing Facebook and WhatsApp business accounts</li>
            <li>Provide a display name and description for your WhatsApp business profile</li>
            <li>Connect and verify your phone number to use for WhatsApp API access</li>
        </ol>`,
        warningMessage : `<div>
            Do <b>not</b> use a number that is currently connected to an
            existing WhatsApp account.
            <br />
            This includes numbers that are connected to the WhatsApp Business App.
        </div>`,
        checkbox :  'I agree to terms and conditions', 
        button : 'Connect Facebook Business Account/Add WhatsApp number'
    
            
    },

    WA_CLIENT_WITHOUT_CUSTOMERID : {
        title : `<h3>Apply for WhatsApp Business APIs</h3>`,
        mainDescription : `<p>To continue, please follow the steps below:</p>`,
        secondaryDescription : `<ol style=" text-align: 'left' ">
            <li>Create an account by clicking the “Register Now” button</li>
            <li>
            Once account created, you will be able to create/connect your Facebook Account and <br />
            create a “WhatsApp Business API Account”
            </li>
        </ol>`,
        warningMessage : `Make sure you use the same email address you used for your Facebook Business Account.`,
        button : 'Register now'
    } ,

    WA_CLIENT_WITH_MIGRATION : {
        title : `<h2 style=" text-align: 'center', margin-top: '2rem', margin-bottom: '2rem' ">WABA Migration</h2>`,
        mainDescription : `<p>Steps you will need to do:
            </br>
            1. Login to your existing Facebook business manager account
            <br />
            2. Complete Step 1 fully, but don’t proceed to Step 2<br />
            3. In Step 1, you will need to:</p>`,
        secondaryDescription : `<div>
            <p>a. <strong><u>Select</u></strong> (and not create) 
            your existing “Facebook Business Account” which is linked with 
            the number you are migrating over </p>
            <br />
            <p>b. <strong><u>Create new</u></strong>
            (and not select) “WhatsApp Business Account”. This is the new 
            account your number will be migrating to. </p>
            <br />
            <p>c. Stop after you reach the account confirmation screen which will say “Accounts have been setup”. We
            will <strong><u>NOT</u></strong> proceed to Step 2.
        </div>`,
        checkbox : 'I agree to terms and conditions',
        button : 'Connect Facebook Business Account/Add WhatsApp number'
    },
    PHONE_MIGRATION : {
        title : '<h2>Phone number migration</h2>',
        notFoundWaba : `<h4>
            Couldnt find WABA Id for this phone number
            <br /> Please contact admin for more information
        </h4>`,
        cannotInitMigration : `<h4>
            Couldnt initiate migration for this phone number
            <br /> Please try again later
        </h4>`,
        prepareOtpTitle : `<h4>
            Please note that as per Facebook&#39;s WhatsApp migration policy, an OTP can be triggered only once per
            13 hours
        </h4>`,
        failOtpTitle : `<h4>Verify OTP failed</h4>`,
        waitOtpTitle : `<h4>An OTP had already been sent.</h4>`,
        sendFailTitle : `<h4>
            Couldnt send OTP to your phone number
            <br />
            Please try again later!
        </h4>`,
        successOtpTitle : `<h4>
            OTP has been sent to your phone number
            <br />
            Please input the OTP to verify
        </h4>`,
    
    }
} as { [key : string] : any }
