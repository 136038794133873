import React, { useEffect, useState } from 'react';
import base64 from 'base-64';
import H from 'history';
import auth0, { AuthOptions } from 'auth0-js';

import config from '../../config';

type Props = {
  isLoggedIn: boolean;
  children: JSX.Element;
  isEmbeddedPage: boolean;
};

function Login({ children, isEmbeddedPage = false }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authConfig: AuthOptions = config.AUTH0_WEBAUTH_CONFIG;
  if(isEmbeddedPage) {
    authConfig.state = '_embeded';
  }

  const webAuth = new auth0.WebAuth(authConfig);

  return (
    <div className="App login-scene">
      {/* <EmailVerificationNotice email={email} webAuth={webAuth} needsEmailVerification={needsEmailVerification} /> */}
      <div
        onClick={() => {
          setIsLoading(true);
          webAuth.authorize({ prompt: 'login' });
        }}
      >
        {React.cloneElement(children, { loading: isLoading })}
      </div>
    </div>
  );
}

export default Login;
