import { ServiceProvider } from './../types';
import { AxiosResponse } from 'axios';
import { axios } from './axios';
import { ApiKey, Partner } from '../types';

const findAllPartner = async () => {
  try {
    const response: AxiosResponse<Partner[]> = await axios.get(`/partners`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const createPartner = async (userId: number, timezone: string) => {
  try {
    const response: AxiosResponse<Partner> = await axios.post(`/partners`, { userId, timezone });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const changeActivation = async (partnerId: number, isActivated: boolean) => {
  try {
    const response: AxiosResponse<Partner> = await axios.patch(`/partners/${partnerId}/changeActivation`, {
      isActivated,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const generateApiKey = async () => {
  try {
    const response: AxiosResponse<ApiKey> = await axios.post('/partners/generateApiKey');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const deactivateApiKey = async () => {
  try {
    const response: AxiosResponse<ApiKey> = await axios.put('/partners/deactivateApiKey');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getApiKey = async () => {
  try {
    const response: AxiosResponse<ApiKey> = await axios.get('/partners/apiKey');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getPartnerApiUsage = async () => {
  try {
    const response = await axios.get(`/partner/getPartnerApiUsage`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAvaiableServiceProviders = async () => {
  try {
    const response: AxiosResponse<ServiceProvider[]> = await axios.get('/partners/serviceProviders');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export {
  findAllPartner,
  createPartner,
  changeActivation,
  getApiKey,
  generateApiKey,
  deactivateApiKey,
  getAvaiableServiceProviders,
  getPartnerApiUsage
};
