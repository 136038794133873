import { AxiosResponse } from 'axios';
import { CloudApiMigration, CloudApiRegisterDTO } from '../types';
import { axios } from './axios';

type FindResponse = {
  migrations: CloudApiMigration[];
  total: number;
};
export const find = async (page: number, size: number, searchText: string) => {
  try {
    const response: AxiosResponse<FindResponse> = await axios.get(
      `/cloud-migration?page=${page}&size=${size}${searchText && '&search=' + searchText}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findCloudRegister = async (page: number, size: number, searchText: string) => {
  try {
    const response: AxiosResponse<FindResponse> = await axios.get(
      `/cloud-migration/register?page=${page}&size=${size}${searchText && '&search=' + searchText}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const register = async (phoneId: number, dto: CloudApiRegisterDTO) => {
  try {
    const res: AxiosResponse<boolean> = await axios.post(`/cloud-migration/${phoneId}`, dto);
    return res.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};
export const registerNewPhoneNumber = async (phoneId: string, pin: string ) => {
  try {
    const res = await axios.post(`/cloud-migration/register/${phoneId}`, { pin });
    return res.data;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};



export const getExportData = async () => {
  try {
    const res = await axios.get(`/cloud-migration/export`);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
