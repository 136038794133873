import React, { useEffect, useState } from 'react';
import { WabaPhoneNumber } from '../../api/account';

const OnboardingStatus = ({ connectResponse }: { connectResponse: WabaPhoneNumber[] | string | null }) => {
  const [confirmationText, setConfirmationText] = useState<string>('');

  useEffect(() => {
    if (!connectResponse) setConfirmationText('');
    else if (typeof connectResponse === 'string')
      setConfirmationText(`Your WABA is created/connected to Keyreply Whatsapp Service
    \n
    But it cannot be connected to KR Portal, you may not see the WABA in the Portal.
    \n
    Please send the WABA Id to admin for support.
    \n`);
    else if (connectResponse.length === 0) {
      setConfirmationText(
        'No account or phone number was added. You might have already connected your account and phone number before.',
      );
    } else {
      let content = '';
      connectResponse.forEach(({ account, newPhoneNumbers }) => {
        const phoneNumberValueList = newPhoneNumbers.map(
          (phoneNumber) =>
            `${phoneNumber.phoneNumber.value} with provision server name: ${
              phoneNumber.provisionServerName || 'Empty'
            }\n`,
        );
        content += `+You have connected WABA ${account.name} with phone numbers:\n`;
        content += phoneNumberValueList.length !== 0 ? phoneNumberValueList.join(' ') : 'None';
        content += '\n';
        if (account?.manager?.partner?.id) {
          content += `${account.manager.partner.user?.email} is partner of this WABA\n`;
        }
      });
      setConfirmationText(content);
    }
  }, [connectResponse]);

  return <div style={{ whiteSpace: 'pre-line' }}>{confirmationText}</div>;
};

export default OnboardingStatus;
