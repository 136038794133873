import React, { useState } from 'react';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button, notification } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

type Props = {
  style?: any;
  getExportData(): Promise<any>;
  fileName: string;
  multiSheets?: {
    fieldSheetName: string;
  };
  combined?: boolean;
  disable: boolean;
};

const chunkArray = (myArray: any[], chunk_size: number) => {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};
const ExportCSV = ({ style, getExportData, fileName, multiSheets, combined = false }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = async (csvData: any[] | [], fileName: string) => {
    try {
      let wb;
      let indexSheet = 0;
      if (combined) {
        wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(csvData);
        XLSX.utils.book_append_sheet(wb, ws, 'WABAs');
      } else if (multiSheets) {
        wb = { SheetNames: [] as any, Sheets: {} as any };
        for (const item of csvData) {
          const ws = XLSX.utils.json_to_sheet([item]);
          wb.SheetNames.push(item[multiSheets.fieldSheetName].toUpperCase());
          wb.Sheets[item[multiSheets.fieldSheetName].toUpperCase()] = ws; // First workbook is named "Sheet1", you can change it
        }
      } else {
        /* create a new blank workbook */
        wb = XLSX.utils.book_new();
        const arrayPaginated = chunkArray(csvData, 10);
        for (const item in arrayPaginated) {
          /* create a worksheet for books */
          const ws = XLSX.utils.json_to_sheet(arrayPaginated[item]);
          indexSheet++;
          /* Add the worksheet to the workbook */
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet' + `${indexSheet}`);
        }
      }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Export Failed`,
        description: `${error}`,
        placement: 'bottomLeft',
      });
    }
  };

  const handleExport = async () => {
    try {
      setIsLoading(true);
      const csvData = await getExportData();
      csvData && csvData.length > 0
        ? exportToCSV(csvData, fileName)
        : notification.warning({
            message: `No Data to Export`,
            placement: 'bottomLeft',
          });
    } catch (error) {
      console.log(error);
      notification.error({
        message: `Export Failed`,
        description: `${error}`,
        placement: 'bottomLeft',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button style={style} icon={<ExportOutlined />} type="primary" onClick={handleExport} loading={isLoading}>
      Export
    </Button>
  );
};

export default ExportCSV;
