const { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_RESPONSE_TYPE, SERVER_BASE_URL, FACEBOOK_APP_ID, REDIRECT_URI, KEYREPLY_BUSINESS_ID, COHERE_API_KEY} = window.appConfig;
const config = { 
  AUTH0_WEBAUTH_CONFIG: {
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    responseType: AUTH0_RESPONSE_TYPE,
    redirectUri: REDIRECT_URI,
  },
  SERVER_BASE_URL: SERVER_BASE_URL,
  FACEBOOK_APP_ID: FACEBOOK_APP_ID,
  KEYREPLY_BUSINESS_ID: KEYREPLY_BUSINESS_ID,
  COHERE_API_KEY: COHERE_API_KEY
 };

export default config;
