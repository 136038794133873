import React from 'react';

import { DualAxes } from '@ant-design/charts';
import { ConversationData } from "../../types"

type AnalyticsChart = (ConversationData & { date: string })[];

type UsageChartProps = {
  usageList: AnalyticsChart;
  loading: boolean;
  totalCost: number;
  totalConversation: number;
};


const transformData = (data : AnalyticsChart) => {
  const res :any = []
  data.forEach((dataPoint) => {
    res.push({
      date : dataPoint.date,
      count : dataPoint.FREE_TIER,
      type : "FREE_TIER"
    })
    res.push({
      date : dataPoint.date,
      count : dataPoint.FREE_ENTRY,
      type : "FREE_ENTRY_POINT"
    })
    res.push({
      date : dataPoint.date,
      count : dataPoint.PAID_BUSINESS,
      type : "PAID_BUSINESS_INIT"
    })
    res.push({
      date : dataPoint.date,
      count : dataPoint.PAID_USER,
      type : "PAID_USER_INIT"
    })

  })
  return res
}

const UsageChart = ({ usageList, loading, totalCost, totalConversation }: UsageChartProps) => {  
  const config = {
    data: [usageList, transformData(usageList)],
    xField: 'date',
    yField: ['cost', 'count'],
    geometryOptions: [
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 3,
          lineDash: [5, 5],
        }
      },
      {
        geometry: 'line',
        seriesField: 'type'
      }
    ],
    interactions: [{ type: 'element-highlight' }, { type: 'active-region' }],
  };
  return (
    <div className="chart-container">
      <div className="flex flex-ai-c">
        <div>
          <h3>{totalCost}</h3>
          <h4 style={{ color: '#faad14' }}>Total Cost</h4>
        </div>
        <div style={{ marginLeft: '50px' }}>
          <h3>{totalConversation}</h3>
          <h4 style={{ color: '#49de00' }}>Total Conversation</h4>
        </div>
      </div>
      <DualAxes loading={loading} autoFit {...config} />
    </div>
  );
};

export default UsageChart;
