import { PageType } from "./type"
export const keysGeneration = (selectedPage : PageType) => {
  if(selectedPage !== "PHONE_MIGRATION"){
    return [
      { key: 'title', title: 'Title' },
      { key: 'mainDescription', title: 'Main description' },
      { key: 'secondaryDescription', title: 'Secondary description' },
      { key: 'warningMessage', title: 'Warning message' },
      { key: 'checkbox', title: 'Checkbox text' },
      { key: 'button', title: 'Button text' },
    ]
  } else {
    return [
      { key: 'title', title: 'Title' },
      { key: 'notFoundWaba', title: 'Content when not found Waba' },
      { key: 'cannotInitMigration', title: 'Content if can\'t initial migration' },
      { key: 'prepareOtpTitle', title: 'Content when begin OTP phase' },
      { key: 'waitOtpTitle', title: 'Content after sending OTP' },
      { key: 'sendFailTitle', title: 'Content when can\'t send OTP' },
      { key: 'failOtpTitle', title: 'Content when OTP verifying fail' },
      { key: 'successOtpTitle', title: 'Content when OTP verifying success' },
    ]
  }
}