import React from 'react';
import { Navigate, Outlet, useLocation } from "react-router-dom"
// import {  } from "react-router"
import { 
  ShareProps,
  LocationState
} from "./types"



function Public({ isLoggedIn } : ShareProps) : JSX.Element {
  const location = useLocation()
  let from = (location.state as LocationState)?.from?.pathname;
  if(!from || from === "/") from = "/accounts"

  if(!isLoggedIn){
    return <Outlet /> 
  }
  return <Navigate to={from} replace />
}

export default Public;