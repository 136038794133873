import { Layout, Image } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Sidebar } from '../scenes/SideBar';

type Props = {
  email: string | null;
  isAdmin?: boolean | null;
  partnerId?: number | null;
};
export default function DashboardLauout({ email, isAdmin, partnerId = null }: Props) {
  const [isCollapse, setIsCollapse] = useState(true);
  return (
    <Layout style={{ minHeight: '90vh', overflow: 'hidden' }} className='flex-dir-r'>
      <Sider
        onMouseEnter={() => setIsCollapse(false)}
        onMouseLeave={() => setIsCollapse(true)}
        style={{ overflow: 'hidden' }}
        theme="light"
        collapsible
        trigger={null}
        collapsed={isCollapse}
        onCollapse={(collapse) => setIsCollapse(collapse)}
        collapsedWidth='60'
      >
        <Sidebar email={email} isAdmin={isAdmin || null} partnerId={partnerId} />
      </Sider>
      <Content className='flex flex-jc-c'><Outlet /> </Content>
    </Layout>
  );
}
