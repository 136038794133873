import React, { useEffect, useState } from 'react';
import { Button, Form, notification } from 'antd';
import { DataType, PartnerContentEditorProps, PageType } from './type';
import { CustomInputField } from '.';
import { postNewContentVersion } from '../../api/partnerDefinedContent';
import { keysGeneration } from "./utils"


const dataHolderGeneration = (selectedPage : PageType | null) => {
  if(!selectedPage) return {} as any
  if(selectedPage !== "PHONE_MIGRATION"){
    return {
      title: '',
      mainDescription: '',
      secondaryDescription: '',
      warningMessage: '',
      actionList: [],
    };
  } else {
    return {
      title: '',
      notFoundWaba: '',
      cannotInitMigration: '',
      prepareOtpTitle: '',
      failOtpTitle: '',
      waitOtpTitle: '',
      successOtpTitle: '',
    };
  }
}


function PartnerContentEditor({ 
  selectedPage, 
  toPreviewMode, 
  refreshing,
  selectedVersionId,
  content
} : PartnerContentEditorProps) {

  const [form] = Form.useForm();
  const [initialValue, setInitialValue] = useState<{[key : string] : any}>({})
  
  const keys = selectedPage && keysGeneration(selectedPage)



  const onFinish = async (values: any) => {

    const result: any = dataHolderGeneration(selectedPage)

    Object.keys(values).forEach((key) => {

      //everything except "checkboxs", "buttons" and value of which is undefined
      if (key && !['checkbox', 'button'].includes(key)) {
        result[key] = values[key];
      }

      if(key === "checkbox"){
        result.actionList &&
        result.actionList.push({
          type: 'checkbox',
          text: values[key],
        })
      }

      if(key === "button"){
        result.actionList &&
        result.actionList.push({
          type: 'button',
          text: values[key],
        })
      }
    });

    if (selectedPage) {
      try {
        await postNewContentVersion(selectedPage, result)
        form.resetFields();
        toPreviewMode()
        refreshing()
        notification.success({
          message: `Success!`,
          description: `This content have created!`,
          placement: 'topRight',
        });
        
      } catch (error) {
        notification.error({
          message: `Error!`,
          description: `This content have not created!`,
          placement: 'topRight',
        });
        
      }
    } else {
      notification.error({
        message: `Error!`,
        description: `You did not choose \`page\``,
        placement: 'topRight',
      });
    }
  };


  const onCancel = () => {
    form.resetFields();
    toPreviewMode();
  };

  const onFieldChange = (key: string, value: string) => {
    const all = form.getFieldsValue();
    form.setFieldsValue({
      ...all,
      [key]: value,
    });
  };

  const onFieldListChange = (key: string, index: number, value: string) => {
    const all = form.getFieldsValue();
    const list = [...all[key]];
    list[index] = value;
    form.setFieldsValue({
      ...all,
      [key]: list,
    });
  };

  useEffect(() => {

    if(content) {
      const data = content.content
      const holder = {} as any

      // map normal fields
      for(const key in data){
        if(key !== "actionList"){
          holder[key] = data[key]
        }
      }

      //map list fields
      if(data.actionList){
        for(const item of data.actionList){
          if(item.type === "checkbox")
            holder.checkbox = item.text
          else
            holder.button = item.text
        }
      } 

      form.setFieldsValue(holder)
      setInitialValue(prev => ({...prev, ...form.getFieldsValue()}))

    }
  }, [content])


  return (
    <>
      {selectedPage === null ? (
        <p> You have to choose a page! </p>
      ) : (
        <Form 
          form={form} 
          autoComplete="off" 
          onFinish={onFinish}
        >

          {/* fields */}
          {keys && keys.map((item) => (
            <React.Fragment key={item.key}>
              {
                /**
                 * page wa-client without customer email : have no checkbox
                 * page wa-client with migration : have no warningMessage
                 */
                (
                  !(
                    selectedPage === "WA_CLIENT_WITHOUT_CUSTOMERID" &&
                    item.key === "checkbox"
                  ) && !(
                    selectedPage === "WA_CLIENT_WITH_MIGRATION" && 
                    item.key === "warningMessage"
                  )
                ) &&
                <CustomInputField 
                  
                  forKey={item.key} 
                  title={item.title} 
                  onFieldChange={onFieldChange}
                  initialValue = { initialValue[item.key] || " " }
                  selectedPage={selectedPage}
                />
              }
            </React.Fragment>
          ))}


          {/* form's action */}
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button className="mr-1em" type="primary" htmlType="submit">
                Save
              </Button>
              <Button type="primary" danger onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default PartnerContentEditor;
