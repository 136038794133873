import React, { useState } from 'react';
import { Button, Modal, notification } from "antd"
import * as api from '../../api';
import { PhoneNumber } from '../../types';

type PhoneCertType = {
  value : PhoneNumber
}
function PhoneCert({ value } : PhoneCertType) {

  const [certificate, setCertificate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleGetCert = async () => {
    let myCert = certificate;
    if (!certificate) {
      try {
        setIsLoading(true);
        const data = await api.phoneNumber.getPhoneCert(value.phoneNumberId);
        if (data?.certificate) {
          setCertificate(data.certificate);
          myCert = data.certificate;
        }
      } catch (error) {
        notification.error({
          message: `Get Cert Failed`,
          placement: 'bottomLeft',
        });
      } finally {
        setIsLoading(false);
      }
    }
    Modal.confirm({
      title: `Your certificate of ${value.verifiedName} is`,
      content: myCert,
      centered: true,
      cancelText: '',
    });
  };

  return (
    <>
      {
        value.certAvailableAt && (
          <Button type="primary" shape="round" size="small" loading={isLoading} onClick={handleGetCert}>
            Cert
          </Button>
        )
      }
    </>
  );
}

export default PhoneCert;