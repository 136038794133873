import React, { useEffect, useState } from 'react';
import { Card, Spin, notification } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { 
  getVersionsByPage, 
  getSelectedContentVersion, 
  getContentByVersionId,
  useDefaultContentVersion
} from '../../api/partnerDefinedContent';

import { 
  VersionType, 
  ContentType, 
  SelectedContentVersionType, 
  PageType 
} from './type';

import { 
  PartnerContentHeader, 
  PartnerContentPreviewer, 
  PartnerContentEditor,
  PartnerLogoUploader
} from '.';
// import PartnerLogoUploader from './PartnerLogoUploader';

function PartnerContent() {
  // storing page
  const [selectedPage, setSelectedPage] = useState<PageType | null>(null);
  // storing version of content from selector
  const [selectedVersion, setSelectedVersion] = useState<VersionType | null>(null);

  // storing content fetched from API
  const [content, setContent] = useState<ContentType | null>(null);
  // storing fetching status
  const [isFetching, setIsFetching] = useState<boolean>(false);

  // storing the mode:  preview | create
  const [isCreating, setIsCreating] = useState<boolean>(false);

  //storing list of version
  const [listOfVersion, setListOfVersion] = useState<VersionType[]>([]);

  // storing using contents version 
  const [usingContents, setUsingContent] = useState<SelectedContentVersionType | null>(null);

  // for refersh
  const [refreshStatus, setRefreshStatus] = useState<boolean>(false)
  const [refreshList, setRefreshList] = useState<boolean>(false)

  const fetchVersionList = async (selectedPage: string) => {
    setContent(null);
    setListOfVersion([]);

    const data: VersionType[] = await getVersionsByPage(selectedPage);
    setListOfVersion((prev) => {
      setSelectedVersion(null);
      return data;
    });
  };

  const fetchContentById = async (id: number) => {
    setIsFetching(true);
    const data = await getContentByVersionId(id);
    setContent(data);
    setIsFetching(false);
  };

  const fetchUseingContentVersion = async () => {
    const data = await getSelectedContentVersion()
    setUsingContent(data)
  }

  // fetch selected content version
  useEffect(() => {
    fetchUseingContentVersion()
  }, [refreshStatus]);

  // fetch version list of this page
  useEffect(() => {
    // TO DO
    // call api here to set data to the Version Selector
    if (selectedPage !== null && selectedPage !== "PARTNER_LOGO") {
      fetchVersionList(selectedPage);
    }
  }, [selectedPage, refreshList]);


  const onUseDefaultContent = async () => {
    try {
      await useDefaultContentVersion(selectedPage);
      // refreshing
      setRefreshStatus(prev => !prev)

      // clear
      setContent(null)
      setSelectedVersion(null)

      // noti
      notification.success({
        message: `Success!`,
        description: `You are using Default Content now!`,
        placement: 'topRight',
      });
    } catch (error) {
      notification.error({
        message: `Error!`,
        description: `Ops! Something wrong...`,
        placement: 'topRight',
      });
    }
  }

  // to preview the selected content
  useEffect(() => {
    if (selectedVersion !== null && selectedVersion.id !== undefined) {
      fetchContentById(selectedVersion?.id);
    }
    if(selectedVersion?.version === "USE_DEFAULT_VERSION"){
      onUseDefaultContent()
    }
  }, [selectedVersion]);

  return (
    <Card
      className="text-center"
      title={
        <PartnerContentHeader
          shouldDisable={isCreating}
          handleSelectPage={(page : PageType) => setSelectedPage(page)}
          listOfVersion={listOfVersion}
          handleSelectVersion={(version) => setSelectedVersion(version)}
          toCreatingMode={() => setIsCreating(true)}
          usingContent={usingContents}
          selectedPage={selectedPage}
          refreshing={() => setRefreshStatus(prev => !prev)}
        />
      }
      style={{ paddingBottom : "2em" }}
    >
      { selectedPage !== "PARTNER_LOGO" &&
        <Spin spinning={isFetching} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
          {!isCreating ? (
            <PartnerContentPreviewer
              content={content}
              selectedPage={selectedPage}
              selectedContent={usingContents}
              selectedVersionId={selectedVersion?.id}
              refreshing={() => setRefreshStatus(prev => !prev)}
            />
          ) : (
            <PartnerContentEditor 
              selectedVersionId={selectedVersion?.id}
              selectedPage={selectedPage} 
              content={content}
              toPreviewMode={() => setIsCreating(false)} 
              refreshing={() => setRefreshList(prev => !prev)}
            /> 
          )}
        </Spin>
      }

      {
        selectedPage === "PARTNER_LOGO" && <PartnerLogoUploader />
      }
    </Card>
  );
}

export default PartnerContent;
