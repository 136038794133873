import React, { useState } from 'react';
import { PhoneNumber } from '../../types'
import { Button, Modal, Form, notification, Input } from "antd"
import * as api from "../../api"

type PhoneProvisionServerType = {
  value : PhoneNumber,
  isAdmin : boolean | null
}
function PhoneProvisionServer({ value, isAdmin } : PhoneProvisionServerType) {

  const [isHover, setIsHover] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isOTPSent, setOTPSent] = useState(false);

  if (!value?.provisionServer) return <></>;
  const provisionName = value.provisionServer.name;
  const providerName = value.provisionServer.serviceProvider.name;
  const options = value.provisionServer.options;
  if (!isAdmin) {
    return <strong>{provisionName}</strong>;
  }

  const onFinish = async (values: any) => {
    const { code } = values;
    const isOTPVerified = await api.phoneNumber.verifyCode(value.id, code);
    if (isOTPVerified) {
      notification['success']({
        message: `OTP is verified!`,
      });
      setModalVisible(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onRequestOTP = async () => {
    const isOTPSent = await api.phoneNumber.requestCode(value.id);
    notification['info']({
      message: `OTP is sent to your phone number!`,
    });
    setOTPSent(isOTPSent);
  };

  return (
    <div>
      <Button
        disabled={!value?.provisionServer}
        type={isHover ? 'primary' : 'dashed'}
        onMouseLeave={() => setIsHover(false)}
        onMouseEnter={() => setIsHover(true)}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        More Infor
      </Button>
      <Modal
        title="Provision Server Infor"
        centered
        closable
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <h3>Provision server: {provisionName}</h3>
        <h4>Service provider: {providerName}</h4>
        <h4>Options:</h4>
        <table>
          <tr>
            <th>Key</th>
            <th>Value</th>
          </tr>
          {Object.entries(options).map(([key, data]) => {
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>{`${data}`}</td>
              </tr>
            );
          })}
        </table>
        {providerName === 'KONVERSE' && options['status'].toLowerCase() === 'otp_required' && (
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
          >
            <Form.Item label="OTP" name="code" rules={[{ required: true, message: 'Please input your OTP!' }]}>
              <Input />
              <Button onClick={onRequestOTP}>Request OTP</Button>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={!isOTPSent}>
                Verify OTP
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}

export default PhoneProvisionServer;