import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Switch, Form, Tooltip, Button } from "antd"
import { PageType } from "./type"
import { defaultContentData } from "./DefaultContentData"

type CustomInputFieldProps = {
    forKey: string
    title: string
    onFieldChange: (key: string, value: string) => void,
    initialValue: string | undefined
    selectedPage: PageType
}

function CustomInputField({
    forKey,
    title,
    onFieldChange,
    initialValue,
    selectedPage
}: CustomInputFieldProps) {

    const [shouldDisable, setShouldDisable] = useState<boolean>(false)
    const [defaultContentController, setDefaultContentController] = useState({
        shouldUseDefaultContent: false,
        defaultContentData: ''
    })

    const [cke, setCke] = useState<typeof CKEditor>(
        <CKEditor
            config={{
                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList',
                    '|', 'undo', 'redo']
            }}
            disabled={shouldDisable}
            editor={ClassicEditor}
            onChange={(e: any, editor: any) => {
                onFieldChange(forKey, editor.getData())
            }}
        />
    )

    const shouldForceEnable = [
        "title", "mainDescription",
        "checkbox", "button",
        "notFoundWaba", 
        "cannotInitMigration",
        "prepareOtpTitle",
        "failOtpTitle",
        "waitOtpTitle",
        "successOtpTitle"
    ].includes(forKey)

    // get default content for a specific field
    useEffect(() => {
        if(selectedPage && forKey){
            if (defaultContentController.shouldUseDefaultContent) {
                setDefaultContentController({
                    ...defaultContentController,
                    defaultContentData : defaultContentData[selectedPage][forKey]
                })
            } else {
                setDefaultContentController({
                    ...defaultContentController,
                    defaultContentData : ''
                })
            }
        }
    }, [defaultContentController.shouldUseDefaultContent])

    // set default content to form data
    useEffect(() => {
        const { defaultContentData } = defaultContentController
        onFieldChange(forKey, defaultContentData)
    }, [defaultContentController.defaultContentData])

    // clear key's value in form whenever user click disable
    useEffect(() => {
        if(shouldDisable){
            onFieldChange(forKey, "")
        }
    }, [shouldDisable])

    
    useEffect(() => {
        
        if (initialValue &&
            !defaultContentController.defaultContentData
        ) {
            
            setCke(<CKEditor
                config={{
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList',
                        '|', 'undo', 'redo']
                }}
                data={initialValue}
                disabled={shouldDisable}
                editor={ClassicEditor}
                onChange={(e: any, editor: any) => {
                    onFieldChange(forKey, editor.getData())
                }}
            />)
        } else if (!initialValue &&
            !defaultContentController.defaultContentData
        ){
            
            setCke(<CKEditor
                config={{
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList',
                        '|', 'undo', 'redo']
                }}
                disabled={shouldDisable}
                editor={ClassicEditor}
                onChange={(e: any, editor: any) => {
                    onFieldChange(forKey, editor.getData())
                }}
            />)
        } else {
            
            setCke(<CKEditor
                config={{
                    toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList',
                        '|', 'undo', 'redo']
                }}
                disabled={shouldDisable}
                editor={ClassicEditor}
                onChange={(e: any, editor: any) => {
                    onFieldChange(forKey, editor.getData())
                }}
                data={defaultContentController.defaultContentData}
            />)
        }
    }, [initialValue, defaultContentController.defaultContentData, shouldDisable])

    return (
        <Form.Item
            name={forKey}
            rules={[{ required: !shouldDisable, message: 'This is required field' }]}
            style={{
                marginBottom: "2em",
                paddingBottom: "0.5em",
                borderBottom: "2px solid gray"
            }}
        >
            <div className='custom-input'>
                <div className="custom-input__controller">
                    {/* disable switch */}
                    <Tooltip title={
                        !shouldDisable ?
                            'Disable this field' :
                            'Enable this field'
                    }>
                        <span style={{ marginRight: "1em" }}>Disable/Enable:</span>
                        <Switch
                            defaultChecked={true}
                            disabled={shouldForceEnable}
                            onChange={() => setShouldDisable(prev => !prev)}
                        />
                    </Tooltip>

                    {/* paste default content button */}
                    <Button
                        type='primary'
                        shape='round'
                        size='small'
                        disabled={shouldDisable}
                        onClick={() => setDefaultContentController(prev => ({
                            ...prev,
                            shouldUseDefaultContent: !prev.shouldUseDefaultContent
                        }))}
                    >{
                            !defaultContentController.shouldUseDefaultContent ?
                                'Paste default content' :
                                'Clear default content'
                        }</Button>
                </div>
                <div className="custom-input__label">{title}:</div>
                <div className="custom-input__editor">

                    {/* render CKEditor */}
                    {
                        cke
                    }

                </div>
            </div>
        </Form.Item>
    );
}

export default CustomInputField;