import React, { ReactChild, useEffect, useState } from 'react';
import { Upload, message, Button, Avatar, notification } from 'antd';
import { LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { uploadLogo, getCurrentLogo } from "../../api/partnerDefinedContent"

const getPreviewUrl = (file : any) => {
  return URL.createObjectURL(file)
}

const EmptyIconStyle = { 
  marginBottom : "1em",
  backgroundColor : "lightgray",
  fontWeight : 600
}
const EmptyIcon = ({ size, children } : {size : number, children : ReactChild}) => (
  <div 
    style={{
      ...EmptyIconStyle, 
      height : `${size}px`,
      width : `${size}px`,
      lineHeight : `${size}px`,
    }}
  >{
    children
  }</div>
)

function PartnerLogoUploader() {

  const [uploadControl, setControl] = useState<{
    loading : boolean,
    file : ArrayBuffer | undefined
  }>({
    loading : false, 
    file : undefined
  })
  const [logoUrl, setUrl] = useState("")

  const handleUpload = async () => {
    // console.log(uploadControl)
    if(uploadControl.file){
      setControl(prev => ({
        ...prev,
        loading: true
      }))
      const data = await uploadLogo(uploadControl.file)
      setControl(prev => ({
        file : undefined,
        loading : false
      }))
      if(data){
        notification.success({
          message: "Uploading was successful.",
          description: "Your logo has been updated!"
        })
        setUrl(data.selectedContentVersions.PARTNER_LOGO)
      } else {
        notification.error({
          message : "The uploading process failed.",
          description: "Something went wrong!"
        })
      }
    }
  }



  const beforeUpload = (file : any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const fileSize = file.size / 1024 / 1024
    const isLt3M = fileSize < 3;
    if (!isJpgOrPng) {
      notification.error({
        message : "Invalid image type",
        description: "You can only upload JPG/PNG file!"
      })
      return false
    }
    if(!isLt3M){
      notification.error({
        message : "Invalid image size",
        description: `Image must smaller than 3MB! Your image is ${fileSize.toFixed(1)}MB`
      })
      return false
    }

    setControl({
      loading : false,
      file
    })
    return false
  }

  const onRemove = (file : any) => {
    setControl({
      loading : false,
      file: undefined
    })
  }

  // fetch current logo
  const fetchLogo = async () => {
    const publicUrl = await getCurrentLogo()
    if(publicUrl){
      setUrl(publicUrl)
    }
  }
  useEffect(() => {
    fetchLogo()
  }, [])


  return ( 
    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* previewer */}
      <div style={{ padding : "1em 3em", }}>
        <h4>Current Logo</h4>
        { !logoUrl && <EmptyIcon size={128}>Empty</EmptyIcon> }
        { 
          logoUrl && 
          <Avatar 
            shape='square'
            size={128}
            style={{marginBottom: "1em"}}
            src={logoUrl}
          />
        }
      </div>

      {/* uploader */}
      <div style={{ padding : "1em 3em" }}>
        <h4 style={{ textAlign: "left", marginBottom: "1em" }}>Upload logo:</h4>
        <div style={{ width : "200px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          { !uploadControl.file && <EmptyIcon size={64}>Preview</EmptyIcon> }
          {  
            uploadControl.file && 
            <Avatar 
              shape="square" 
              size={64} 
              style={{marginBottom: "1em"}} 
              src={getPreviewUrl(uploadControl.file)}
            />
          }
          <Upload
            maxCount={1}
            accept='.jpg,.png'
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            disabled={uploadControl.loading}
          >
              <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>

          <Button
            type="primary"
            onClick={handleUpload}
            disabled={!uploadControl.file || uploadControl.loading}
            // loading={uploading}
            style={{ marginTop: 16 }}
          >{ uploadControl.loading ? "Uploading..." : "Upload"}</Button>
        </div>
      </div>
    </div> 
  );
}

export default PartnerLogoUploader;